html {
  height: 100%;
}

body {
  #root {
    .App {

      min-height: 100vh;

      &.not-logged-in {
        padding: 0 0 100px 0;
        margin: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: 100%;
        min-height: 100vh;

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000031+0,2e3b89+100 */
        background: #000031;
        /* Old browsers */
        /* FF3.6-15 */
        /* Chrome10-25,Safari5.1-6 */
        background: -webkit-gradient(linear, left top, left bottom, from(#000031), to(#2e3b89));
        background: linear-gradient(to bottom, #000031 0%, #2e3b89 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000031', endColorstr='#2e3b89',GradientType=0 );
        /* IE6-9 */
        background-repeat: no-repeat;
        background-attachment: fixed;
        border: 0;
        border-bottom: 0;
        /* border-radius: 0; */
      }

      &.logged-in {
        background-color: map_get($theme-colors, 'brand');
      }
    }

  }
}

.badge {
  text-shadow: none;
  font-weight: normal;
  /* border-radius: .25rem; */
}

.badge-primary {
  background-color: map_get($theme-colors, 'actvOrange');
}

.badge-secondary {
  background-color: map_get($theme-colors, 'primary');
}

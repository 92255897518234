.btn {
  border: 0;
  border-radius: 2px;
  text-decoration: none;
  font-weight: 400;
}

.btn-primary {
  background-color: map_get($theme-colors, 'primary');
  color: white;
}

.btn-secondary {
  background-color: map_get($theme-colors, 'secondary');
  color: white;
}

.btn-default {
  background-color: map_get($theme-colors, 'secondary');
  color: white;
  &:hover {
    color: white;
  }
}


.btn-ghost {
  background-color: transparent;
  color: #777;
  border: 1px solid #777;

  &.dashboardUsersActionToggle {
    color: #777 !important;
    &:hover {
      color: white !important;
    }
  }

}

/*

.btn-primary {
  background-color: #008fd5;
  color: #fff !important;
}


.btn-dialing {
  background-color: transparent;
  color: #ccc;
}

.btn-wide {
  padding-left: 25px;
  padding-right: 25px;
}

.btn.btn-primary.btn-lg i.fa-caret-right {
  position: relative;
  top: 1px;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
*/

body {
  font-family: "Lato", sans-serif;

  h1,
  h2,
  h3 {
    /* font-weight: 300; */
  }

  h4,
  h5 {
    /* font-weight: 300; */
  }

  p {
    /* font-size: 1em; */
    /* font-weight: 300; */
  }

  a.dashedUnderline {
    text-decoration: none !important;
    border-bottom: 1px dashed #ccc;
  }

}

main {
  h1 {
    color: #fff;
  }
}

.highlight {
  display: inline;
  background-color: #ccff00;
  color: #000000;
  box-decoration-break: clone; /* See https://stackoverflow.com/questions/34659853/how-to-apply-padding-to-every-line-in-multi-line-text */
}
form {
  .help-block {
    margin-top: 3px;
  }
}


form label {
  color: #ccc;
  /* font-size: 1.125em; */

  input[type=radio] {
    margin-right: 5px;
  }
}

form input {
  font-size: 1.125em;
  border-radius: 2px;
}

form input::-webkit-input-placeholder {
  color: #aaa !important;
}

form input::-moz-placeholder {
  color: #aaa !important;
}

form input::-ms-placeholder {
  color: #aaa !important;
}

form input::-webkit-input-placeholder {
  color: #aaa !important;
}

form input:-ms-input-placeholder {
  color: #aaa !important;
}

form input::-ms-input-placeholder {
  color: #aaa !important;
}

form input::placeholder {
  color: #aaa !important;
}

form select {
  padding: 7px;
  color: #333;
}

form .help-block {
  color: #999;
  font-size: 0.875em;
  font-style: italic;
}

form .form-group-mediumlarge {
  max-width: 450px;
}

form .form-group-medium {
  max-width: 350px;
}

form .form-group-narrow {
  max-width: 250px;
}

form fieldset {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 25px;
  position: relative;
}

form fieldset legend {
  position: absolute;
  top: -18px;
  left: 0;
}

form fieldset legend span {
  background-color: #ccc;
  color: #0f124a;
  /* font-weight: 300; */
  font-size: 0.6875em;
  padding: 3px 10px;
}


.show {
  .dashboardUsersActionToggle {
    color: #fff !important;
  }
}

.block-account #accountNo {
  width: 188px;
  text-align: center;
}

.form-control {
  &.server-error {
    border: 1px solid #c00;
  }
}

.App.not-logged-in {

  /* Default text color when not logged in */
  color: white;

  header {
    background: transparent;
    #logo {
      max-height: 90px;
    }
  }

  h4 {
    color: map_get($theme-colors, 'brand');
  }

  p {
    color: #ccc;
    a {
      color: #ccc;
      text-decoration: underline;
      &:hover {
        color: white;
      }
    }
  }

  .btn-primary {
    background-color: map_get($theme-colors, 'brand');
  }

  ul li.list-group-item {
    color: #212529 !important;
  }

  ul#userlist {
    max-width: 450px;
  }

  ul#userlist li div {
    width: 30%;
  }

}

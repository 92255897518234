.modal {
  /* text-align: center; */
}

/*
.modal .modal-dialog .modal-content .modal-header h5.modal-title {
  color: #333;
}
*/
.modal-title {
  color: #333;
  /* font-weight: 300; */
}

.modal .modal-dialog .modal-content .modal-body {
  color: #333;
}

.modal .modal-dialog .modal-content .modal-body p,
.modal .modal-dialog .modal-content .modal-body h1,
.modal .modal-dialog .modal-content .modal-body h2,
.modal .modal-dialog .modal-content .modal-body h3,
.modal .modal-dialog .modal-content .modal-body h4,
.modal .modal-dialog .modal-content .modal-body h5,
.modal .modal-dialog .modal-content .modal-body li {
  color: #333;
}

.modal .modal-dialog .modal-content .modal-body h1,
.modal .modal-dialog .modal-content .modal-body h2,
.modal .modal-dialog .modal-content .modal-body h3,
.modal .modal-dialog .modal-content .modal-body h4,
.modal .modal-dialog .modal-content .modal-body h5 {
  font-weight: bold;
}

.modal .modal-dialog .modal-content .modal-body .warning {
  background-color: #c00;
  color: #fff;
  padding: 10px 15px;
}

.modal .modal-dialog .modal-content .modal-body .warning p,
.modal .modal-dialog .modal-content .modal-body .warning h1,
.modal .modal-dialog .modal-content .modal-body .warning h2,
.modal .modal-dialog .modal-content .modal-body .warning h3,
.modal .modal-dialog .modal-content .modal-body .warning h4,
.modal .modal-dialog .modal-content .modal-body .warning h5,
.modal .modal-dialog .modal-content .modal-body .warning li {
  color: #fff;
}

.modal .modal-dialog .modal-content .modal-body .form-group label,
.modal .modal-dialog .modal-content .modal-body .form-check label
 {
  color: #333;
}

.card-dashcard {
  background-color: white;
  border: 0;

  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }

  > .card-header {
    background-color: map_get($theme-colors, 'primary');
    color: white;

    h2 {
      font-size: 18px;
      line-height: 29px;
      text-transform: uppercase;
      margin-bottom: 0;

      span {
        font-size: 14px;
        text-transform: none;
      }
    }

    .btn-ghost {
      color: #fff;
      border-color: #fff;
      &:hover {
        background-color: #fff;
        color: map_get($theme-colors, 'primary');
      }
    }
  }

  > .card-body {

    .list-group .list-group-item {
      background-color: transparent;
    }

    p {
      color: #333;
      font-weight: normal;

      a {
        color: map_get($theme-colors, 'primary');
        text-decoration: underline;
      }

      &.muted {
        a {
          color: #bbb;
        }
      }
    }
  }

}

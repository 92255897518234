.alert-formerror {
  background-color: #c00;
  color: #fff;
}

.error-message {
  display: inline-block;
  background-color: #c00;
  color: #fff;
  padding: 6px 12px;
  text-align: center;
}


/* This style is used when attempting to delete a user */
.modal .modal-dialog .modal-content .modal-body .warning {
  background-color: #c00;
  color: #fff;
  padding: 10px 15px;
  p,h1,h2,h3,h4,h5,li {
    color: #fff;
  }
}

header {
  /* background-color: map_get($theme-colors, 'primary'); */



  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000031+0,2e3b89+100 */
  background: #000031;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(#000031), to(#2e3b89));
  background: linear-gradient(to bottom, #000031 0%, #2e3b89 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000031', endColorstr='#2e3b89',GradientType=0 );
  /* IE6-9 */
  background-repeat: no-repeat;
  /* background-attachment: fixed; */

  #logo {
    margin: 0 auto;
    max-height: 75px;
  }

  .col-usertools {
    a {
      color: #fff;
    }
  }

}

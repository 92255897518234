/* Import fonts */
@import url(//fonts.googleapis.com/css?family=Lato:400,700);
@import url(//netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.css);

/* Override various Bootstrap variables  */
$brand-primary: #293b86;

/* $body-bg: #008fd5; */

$theme-colors: (
  "primary": $brand-primary,
  "secondary": #666666,
  "brand": #008fd5,
  "actvOrange": #c75200  // was f47a42 but contrast was increased to pass WCAG 2.0 accessibility requirements
);

$enable-rounded: true;
$border-radius: 2px;

/* Import Bootstrap */
@import '~bootstrap/scss/bootstrap.scss';

/* Import our own styles */
@import 'scss/body';
@import 'scss/typography';
@import 'scss/badges';
@import 'scss/buttons';
@import 'scss/cards';
@import 'scss/forms';
@import 'scss/modals';
@import 'scss/header';
@import 'scss/footer';
@import 'scss/errors';
@import 'scss/reactMisc';

// Load me last
@import 'scss/notLoggedIn';
